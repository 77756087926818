import React from "react"

import Corporate from "../ingredients/@corporate.js"

export default function Corporate_Single() {
  return (
    <>
      <Corporate />
    </>
  )
}